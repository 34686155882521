import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '@mdi/react';
import { mdiCheckCircle } from '@mdi/js';
import './payu.css';

const PayUComponent = ({ user, info }) => {
    const { t } = useTranslation();

    if (!info || !info.payu) {
        return <div>{t('payment.error')}</div>;
    }

    const plans = [
        {
          name: t('landing-plan.free.name'),
          price: '0',
          period: '',
          features: [
            t('landing-plan.free.feature-1'),
            t('landing-plan.free.feature-2'),
            t('landing-plan.free.feature-3'),
            t('landing-plan.free.feature-4'),
            t('landing-plan.free.feature-5'),
            t('landing-plan.free.feature-6')
          ],
          footerText: t('landing-plan.free.footerText'),
          highlighted: false,
          button: true
        },
        {
          name: t('landing-plan.premium-monthly.name'),
          monthlyPrice: '2.99',
          annualMonthlyPrice: '2.49',
          features: [
            t('landing-plan.premium-monthly.feature-1'),
            t('landing-plan.premium-monthly.feature-2'),
            t('landing-plan.premium-monthly.feature-3'),
            t('landing-plan.premium-monthly.feature-4'),
            t('landing-plan.premium-monthly.feature-5'),
            t('landing-plan.premium-monthly.feature-6')
          ],
          footerText: t('landing-plan.premium-monthly.footerText'),
          highlighted: false
        }
      ];

    const planInfo = {
        name: t('landing-plan.premium-monthly.name'),
        annualPrice: '29.88',
        monthlyPrice: '2.99',
        annualMonthlyPrice: '2.49',
        features: [
            t('landing-plan.premium-monthly.feature-1'),
            t('landing-plan.premium-monthly.feature-2'),
            t('landing-plan.premium-monthly.feature-3'),
            t('landing-plan.premium-monthly.feature-4'),
            t('landing-plan.premium-monthly.feature-5'),
            t('landing-plan.premium-monthly.feature-6')
        ],
        footerText: t('landing-plan.premium-monthly.footerText'),
        highlighted: false
    };
 
    return (
        <div className="payu-container">
            <div className="text-center">
                <h2 className="payu-title">{t('payment')}</h2>
                <hr className="payu-divider"/>
            </div>

            <div className="text-center mb-4">
               
                <div className="payu-benefits-container">
                    <ul className="payu-benefits-list">
                        {planInfo.features.map((feature, index) => (
                            <li key={index}>
                                <Icon path={mdiCheckCircle} size={0.8} className="feature-icon" />
                                <span>{feature}</span>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>

            <div className="row justify-content-center">
                <div className="col-lg-6 col-md-6 mb-4">
                    <div className={`card pricing-card h-100 `}>
                        
                        <div className="card-body text-center">
                        
                            <div className="pricing-value mb-4">
                                <div className="mb-3">
                                    <span className="currency">$</span>
                                    <span className="price">{planInfo.annualPrice}</span>
                                    <span className="period">/{t('landing-plan.period.year')}</span>
                                </div>
                            </div>
                        
                        </div>

                        <div className="text-center mb-4">
                            <div className="payu-plan-buttons">
                                <form method="post" action={info.payu.payuFormUrl}>
                                    <input name="merchantId" type="hidden" value={info.payuAnnual.merchantId} />
                                    <input name="accountId" type="hidden" value={info.payu.accountId} />
                                    <input name="description" type="hidden" value={info.payu.description} />
                                    <input name="referenceCode" type="hidden" value={info.payu.referenceCode} />
                                    <input name="amount" type="hidden" value={info.payu.amount} />
                                    <input name="tax" type="hidden" value={info.payu.tax} />
                                    <input name="taxReturnBase" type="hidden" value={info.payu.taxReturnBase} />
                                    <input name="currency" type="hidden" value={info.payu.currency} />
                                    <input name="signature" type="hidden" value={info.payu.signature} />
                                    <input name="test" type="hidden" value={info.payu.test} />
                                    <input name="buyerEmail" type="hidden" value={user.email} />
                                    <input name="responseUrl" type="hidden" value={info.payu.responseUrl} />
                                    <input name="confirmationUrl" type="hidden" value={info.payu.confirmationUrl} />
                                    <button type="submit" className="payu-submit-button">
                                        {t('payment.submitButton')}
                                    </button>
                                </form>
                            </div>
                        </div>
                        
                    </div>
                </div>

                <div className="col-lg-6 col-md-6 mb-4">
                    <div className={`card pricing-card h-100 `}>
                        
                        <div className="card-body text-center">
                        
                            <div className="pricing-value mb-4">
                                <div className="mb-3">
                                    <span className="currency">$</span>
                                    <span className="price">{planInfo.monthlyPrice}</span>
                                    <span className="period">/{t('landing-plan.period.month')}</span>
                                </div>
                             
                            </div>
                        
                        </div>

                        <div className="text-center mb-4">
                            <div className="payu-plan-buttons">
                                <form method="post" action={info.payu.payuFormUrl}>
                                    <input name="merchantId" type="hidden" value={info.payu.merchantId} />
                                    <input name="accountId" type="hidden" value={info.payu.accountId} />
                                    <input name="description" type="hidden" value={info.payu.description} />
                                    <input name="referenceCode" type="hidden" value={info.payu.referenceCode} />
                                    <input name="amount" type="hidden" value={info.payu.amount} />
                                    <input name="tax" type="hidden" value={info.payu.tax} />
                                    <input name="taxReturnBase" type="hidden" value={info.payu.taxReturnBase} />
                                    <input name="currency" type="hidden" value={info.payu.currency} />
                                    <input name="signature" type="hidden" value={info.payu.signature} />
                                    <input name="test" type="hidden" value={info.payu.test} />
                                    <input name="buyerEmail" type="hidden" value={user.email} />
                                    <input name="responseUrl" type="hidden" value={info.payu.responseUrl} />
                                    <input name="confirmationUrl" type="hidden" value={info.payu.confirmationUrl} />
                                    <button type="submit" className="payu-submit-button">
                                        {t('payment.submitButton')}
                                    </button>
                                </form>
                            </div>
                        </div>
                        
                    </div>
                </div>
               
            </div>

           
        </div>
    );
};

export default PayUComponent;
